.footer {
  background: linear-gradient(to bottom, #000, #111, #222);
  color: #f8f8cf;
  padding: 20px 0;
  text-align: center;
	box-shadow: 0 0 10px var(--main-color-alt);
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer p {
  margin: 10px 0;
}

.footer a {
  color: #f9b234;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer-rights {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
