.home {
	position: relative;
	margin-top: 72px;
	width: 100%;
	justify-content: space-between;
	color: #fff;
	min-height: 100vh;
	display: flex;
	align-items: center;
	box-shadow: 0 0 3px var(--main-color-alt);
}

.home::before {
	content: "";
	position: absolute;
	left: 0;
	top: -10px;
	width: 100%;
	background-image: url(../../assets/home3.png);
	height: calc(100% + 10px);
	opacity: 0.2;
	backdrop-filter: blur(10px); /* Blur effect for the glass */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
	transform-origin: top left;
	margin-top: 0;
}

.textAnimation {
	padding-left: 30px;
	z-index: 1;
}

.home span {
	font-size: 30px;
	width: 40%;
	line-height: 1.5;
	height: 100px;
	margin-bottom: 10px;
}

.welcomeImage {
	width: 45%;
	display: flex;
	justify-content: center;
}

@media (max-width: 991px) {
	.welcomeImage {
		display: none;
	}
	.home span {
		width: 100%;
	}
}

.welcomeImage img {
	width: 400px;
	height: 400px;
	position: relative;
	animation: up-and-down 5s linear infinite;
}

@keyframes up-and-down {
	0%,
	100% {
		top: 0;
	}
	50% {
		top: -50px;
	}
}

@media (max-width: 450px) {
	.textAnimation {
		padding-left: 15px;
		padding-right: 15px;
	}
	.home {
		min-height: 100vh;
	}
	.home span {
		font-size: 22px;
	}
}
