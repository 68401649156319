.riddles {
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
	margin: 45px 0 0;
	width: 100%;
	height: fit-content;
	z-index: 7;
}

.riddle {
	position: relative;
}

.hint-container {
	position: absolute;
	right: 10px;
	top: 10px;
	background-color: #444;
	border-radius: 6px; /* Rounded corners */
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow */
	z-index: 10;
	max-width: 300px; /* Limit the width */
	gap: 10px; /* Space between icon, text, and image */
}

.hint-content {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.hintText {
	color: #f0f0f0; /* Softer white color */
	font-size: 18px; /* Larger text */
	margin: 0 absolute;
	margin-bottom: 10px; /* Space below text */
	line-height: 1.4; /* Improved line height */
}

.hintText.show {
	display: block;
	margin-left: 6px;
}

.hintText.hide {
	display: none;
}

.hintIcon-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.hintIcon {
	font-size: 36px; /* Larger icon */
	color: #ffd700; /* Bright color */
	cursor: pointer;
	z-index: 15;
	transition: color 0.3s, transform 0.3s; /* Transition effects */
}

.hintIcon:hover {
	color: #fff700; /* Color on hover */
	transform: scale(1.1); /* Slight scale effect */
}

.no-hint-image {
	width: 34px;
    height: 40px;
    margin-left: 6px;
}

.riddle,
.lock-container {
	background-color: #1a1a1a;
	border-radius: 10px;
	box-shadow: 0 0 10px var(--main-color-alt);
	overflow: hidden;
	width: 300px;
	height: 342px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 10px; /* Adjust as needed */
}

.riddle .riddleImage {
	max-width: 100%;
	height: auto;
	object-fit: cover;
	border-radius: 10px 10px 0 0;
}

.riddle input {
	width: 80%; /* Adjusted width to accommodate the arrow */
	padding: 10px;
	border: none;
	background-color: #1a1a1a;
	color: #ececec;
	border-radius: 0 0 10px 10px;
	box-sizing: border-box;
	outline: none;
}

.riddle .arrow-icon {
	color: #ececec;
	cursor: pointer;
	font-size: 30px;
	padding: 6px;
	margin-left: auto;
}

.riddle .arrow-icon:hover {
	background-color: #333333; /* Hover color */
}

.lock-container {
	position: relative;
	width: 300px;
	height: 342px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.lock-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	background-color: #222; /* Adjust transparency as needed */
	display: flex;
	justify-content: center;
	align-items: center;
}

.lock {
	color: #fff; /* Lock icon color */
	font-size: 100px; /* Adjust as needed */
}

.modal {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
	z-index: 1000;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	pointer-events: none; /* Disable pointer events when not visible */
}

.modal.visible {
	opacity: 1;
	pointer-events: auto; /* Enable pointer events when visible */
}

.modal-content {
	background: #4caf50; /* Green background color */
	color: #fff; /* White text color */
	padding: 20px;
	border-radius: 10px;
	text-align: center;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.overlay {
	background-color: #272622;
	box-shadow: 0 0 6px var(--main-color-alt);
	width: 300px;
	height: 342px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: space-around;
	transition: background 0.3s ease-in-out;
	overflow: hidden;
	margin: 10px;
}

.overlay p {
	font-size: 85px;
}

.overlay:hover {
	cursor: pointer;
	background-color: #3d3b36; /* Change to the desired flash color */
}

.removeOverlay {
	display: none;
}

.paginationContainer {
	display: flex;
	flex-wrap: wrap;
	z-index: 4;
	justify-content: space-evenly;
	width: 100%;
}

.paginationContainer ul {
	width: 100%;
	margin: auto;
	margin-top: 50px;
	font-size: 20px;
	gap: 10px;
	padding: 0;
	display: flex;
	color: var(--main-color-alt);
	align-items: center;
	justify-content: center;
}

.paginationContainer a {
	text-decoration: none;
	cursor: pointer;
	height: 100%;
	padding: 0 9px;
}

.paginationContainer li.previous a,
.paginationContainer li.next a {
	padding: 0 5px;
}

.paginationContainer li {
	border: 1px solid var(--main-color-alt);
	border-radius: 50%;
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
}

.paginationContainer li svg {
	margin-top: 5px;
	padding: 0;
}

.paginationContainer .active a {
	font-weight: 600;
}

.paginationContainer .pagination-item {
	color: var(--main-color-alt); /* Default color for pagination items */
}

.paginationContainer .active {
	background-color: yellow; /* Background color for active pagination item */
	color: black; /* Text color for active pagination item */
	border: 1px solid yellow; /* Border color for active pagination item */
}
