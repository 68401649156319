:root {
  --main-color: #222222;
  --main-color-alt: #f5c700;
  --main-title-color: #f8f8cf;
  --main-transition: 0.3s;
  --main-padding-top: 50px;
  --main-padding-bottom: 50px;
}

::selection {
  color: #000;
  background: #ffe32e;
}

body {
  background-color: #333;
  font-family: 'Jost', sans-serif;
}

html {
  scroll-behavior: smooth;
}

/* Spinner styles */
.loading-spinner {
  border: 10px solid #f3f3f3; /* Light gray */
  border-top: 10px solid var(--main-color-alt); /* Blue */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height to center spinner vertically */
}
