.activateAccount {
  width: 100%;
  height: 100%;
  text-align: center;
  color: #f8f8f8;
}

.activateAccount .loginLink {
  color: var(--main-color-alt)
}

.activateAccount .error {
  margin-top: 20px;
  color: #d9534f;
  text-align: center;
}