.forgotPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 10px;
  width: fit-content;
  border-radius: 10px;
  background: linear-gradient(145deg, #111, #222);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.forgotPassword .title {
  font-size: 24px;
  color: var(--main-color-alt);
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 20px;
  font-family: "Playfair Display", serif;
  text-align: center;
}

.forgotPassword p {
  color: #f8f8cf;
  font-size: 1em;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 20px;
  max-width: 400px;
}

.forgotPassword form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.forgotPassword input {
  width: 300px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #444;
  background: #333;
  color: #f8f8cf;
  margin-bottom: 20px;
  font-size: 1em;
  transition: border-color 0.3s;
}

.forgotPassword input:focus {
  border-color: var(--main-color-alt);
  outline: none;
}

.forgotPassword button {
  width: 200px;
  height: 40px;
  color: #fff;
  background: var(--main-color-alt);
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  transition: background 0.3s ease;
  cursor: pointer;
}

.forgotPassword a {
  cursor: pointer;
}

.forgotPassword button:hover {
  background: #ecb911cc;
}

.responseMessage {
  color: #f8f8cf;
  margin-top: 20px;
  text-align: center;
}
