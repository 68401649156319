/* style.css */

.passwordReset {
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.passwordReset h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.passwordResetForm label {
  display: block;
  margin-bottom: 8px;
  color: #555;
}

.passwordResetForm input[type="password"] {
  width: 95%;
  padding: 10px ;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.passwordResetForm button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.passwordResetForm button:hover {
  background-color: #0056b3;
}

.passwordReset .error {
  margin-top: 20px;
  color: #d9534f;
  text-align: center;
}

.success {
  margin-top: 20px;
  color: #28a745;
  text-align: center;
}
