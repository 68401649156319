.signupLoginContainer {
  padding-top: var(--main-padding-top);
  padding-bottom: var(--main-padding-bottom);
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Jost', sans-serif;
  background: linear-gradient(135deg, #1a1a2e, #0f0f1a, #1a1a2e);
}

.signupLoginContainer .title {
  text-decoration: none;
  font-size: 30px;
  color: var(--main-color-alt);
  cursor: pointer;
  font-weight: bold;
  margin: 0;
  margin-bottom: 15px;
  z-index: 4;
  font-family: "Playfair Display", serif;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signupLoginContainer .main {
  width: 350px;
  height: 550px;
  background: #222;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: relative;
}

.signupLoginContainer a {
  cursor: pointer;
}

#chk {
  display: none;
}

.signupLoginContainer .signup,
.signupLoginContainer .login {
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: transform 0.8s ease-in-out;
}

.signupLoginContainer .main label {
  color: var(--main-color-alt);
  font-size: 1.8em;
  margin-bottom: 50px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.signupLoginContainer .main input {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #444;
  background: #333;
  color: #f8f8cf;
  font-size: 1em;
  transition: border-color 0.3s;
}

.signupLoginContainer .main input:first-of-type {
  margin-top: 50px;
}

.signupLoginContainer .main input:focus {
  border-color: var(--main-color-alt);
  outline: none;
}

.signupLoginContainer button {
  width: 80%;
  padding: 10px;
  margin: 20px 0;
  color: #fff;
  background: var(--main-color-alt);
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  transition: background 0.3s ease;
  cursor: pointer;
}

.signupLoginContainer button:hover {
  background: #ecb911cc;
}

.signupLoginContainer .login {
  background: #333;
  transform: translateY(80%);
}

.signupLoginContainer .login label {
  transform: scale(0.8);
  margin-bottom: 50px;
  color: var(--main-color-alt);
}

.signupLoginContainer .login a {
  color: var(--main-color-alt);
  text-decoration: none;
  font-weight: bold;
}

#chk:checked ~ .login {
  transform: translateY(17%);
}

.signupLoginError {
  color: #d9534f;
  text-align: center;
  font-weight: 500;
}

.signupLoginSuccess {
  color: rgb(20, 143, 20);
  text-align: center;
  font-weight: 500;
}

.forgotPasswordLink {
  color: #f8f8cf;
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.spinner {
  margin-top: 20px;
}

@media (max-width: 900px) {
  .signupLoginContainer {
    flex-direction: column;
    margin-top: 50px;
  }

  .signupLoginContainer .main {
    margin: 30px 0;
  }
}

@media (max-width: 450px) {
  .signupLoginContainer .title {
    font-size: 24px;
  }
  .signupLoginContainer {
    margin-top: 0;
  }
  .signupLoginContainer .main {
    width: 300px;
    height: 520px;
  }

  .signupLoginContainer .login {
    transform: translateY(81%);
  }

  .signupLoginContainer .main input:first-of-type {
    margin-top: 40px;
}

  .signupLoginContainer label {
    font-size: 1.5em;
  }

  .signupLoginContainer .main input {
    height: 20px;
  }

  .signupLoginContainer button {
    width: 70%;
  }

  .forgotPasswordLink {
    font-size: 0.9em;
  }
}
