.header {
	background-color: var(--main-color);
	box-shadow: 0 0 10px var(--main-color-alt);
	top: 0;
	width: 100%;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed; /* Change to fixed to keep it on top */
	transition: top 0.3s; /* Smooth transition for hide/show */
	z-index: 1000; /* Ensure it overlays other content */
}

.header.hide {
	top: -70px; /* Hides the Navbar off-screen */
}

.header .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
}

.header .container .title {
	text-decoration: none;
	font-size: 26px;
	color: var(--main-title-color);
	font-weight: bold;
	margin: 0;
	padding-left: 20px;
	font-family: "Playfair Display", serif;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 767px) {
	.header .title {
		height: 40px;
		font-size: 20px;
	}
}

.header .main-nav {
	display: flex;
	align-items: center;
	height: 100%;
	margin: 0;
	list-style: none;
	padding: 0;
}

.header .main-nav li {
	height: 100%;
}

.header .main-nav li a {
	display: flex;
	justify-content: center;
	text-decoration: none;
	color: var(--main-title-color);
	align-items: center;
	height: 100%;
	font-size: 18px;
	position: relative;
	padding: 0 30px;
	transition: 0.3s;
	overflow: hidden;
}

@media (max-width: 950px) {
	.header .main-nav {
		position: relative;
		height: fit-content;
	}
	.header .main-nav li a {
		font-size: 18px;
		height: 100%;
		padding: 15px 0;
		width: 100%;
	}
	.header .main-nav li {
		width: 100%;
		height: 100%;
	}
}

.header .main-nav li a:hover {
	color: var(--main-color-alt);
	background-color: #555555;
}

.toggleButton svg {
	font-size: 25px;
	color: white;
	transition: var(--main-transition);
}

.toggleButton svg:hover {
	color: var(--main-color-alt);
}

.toggleButton button {
	margin-right: 20px;
	background-color: transparent;
	border: none;
	color: black;
	cursor: pointer;
}

@media (min-width: 951px) {
	.header .toggleButton {
		display: none;
	}
	.header .main-nav li a::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 0px;
		background-color: var(--main-color-alt);
		transition: var(--main-transition);
		top: 0;
		left: -100%;
	}
	.header .main-nav li a:hover:before {
		left: 0;
		height: 2px;
	}
}

@media (max-width: 950px) {
	.header .main-nav {
		display: none;
	}
	.toggleButton button {
		margin: 0;
	}
}

@media (max-width: 991px) {
	.header .container {
		flex-wrap: nowrap;
	}
	.header .container .main-nav a {
		width: fit-content;
	}
}

#open .header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
}

#open .main-nav {
	display: flex;
	flex-direction: column;
	background-color: var(--main-color);
	position: absolute;
	top: 70px; /* Adjust based on header height */
	left: 0;
	width: 100%;
	max-height: calc(100vh - 70px); /* Prevent overflow beyond the viewport */
}

#open .main-nav li a {
	padding: 15px 0;
	text-align: center;
	width: 100%;
}

#open .main-nav li a::after {
	content: "";
	position: absolute;
	width: 100%;
	z-index: 100;
	height: 0px;
	background-color: var(--main-color-alt);
	transition: var(--main-transition);
	bottom: 0;
	z-index: 2;
	left: -100%;
}

#open .main-nav li a:hover:after {
	height: 2px;
	left: 0;
}

#open .title {
	display: none;
}

#open .toggleButton {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

@media (max-width: 450px) {
	.header .container .title {
		font-size: 24px;
	}
	#open .main-nav li a {
		font-size: 17px;
	}

}
